import { toast } from '@campus/components';
import { getLibraryObjectAccessInfos } from '../../../../services/libraryObject';
import { attributes, classNames, numberOfColumns } from './constants';
export function calculateIndicatorMargin(triggerElement, contentElement) {
    const triggerRect = triggerElement.getBoundingClientRect();
    const contentRect = contentElement.getBoundingClientRect();
    return triggerRect.left - contentRect.left + triggerRect.width / 2;
}
function getListItemsForColumn(listElement, columnIndex) {
    const selector = `[${attributes.columnIndex}='${columnIndex}']`;
    return listElement.querySelectorAll(selector);
}
function getChapterNumberWidth(listItem) {
    const selector = '.' + classNames.chapterNumber;
    return listItem.querySelector(selector).scrollWidth;
}
export function calculateChapterNumberWidthForEachColumn(listElement) {
    const widths = Array.from({ length: numberOfColumns });
    for (let i = 0; i < numberOfColumns; ++i) {
        const listItems = getListItemsForColumn(listElement, i);
        if (listItems.length === 0)
            continue;
        const iterator = listItems.values();
        widths[i] = getChapterNumberWidth(iterator.next().value);
        for (const item of iterator) {
            const width = getChapterNumberWidth(item);
            if (width > widths[i]) {
                widths[i] = width;
            }
        }
    }
    return widths;
}
export function getChapterIdFromListClickEvent(event) {
    if (!(event.target instanceof Element))
        return null;
    const listItemElement = event.target.closest(`[${attributes.chapterId}]`);
    if (!listItemElement)
        return null;
    const button = listItemElement.querySelector('button');
    if (button?.disabled)
        return null;
    return listItemElement.getAttribute(attributes.chapterId) ?? null;
}
function getChapter(chapterId, libraryObject) {
    return libraryObject.chapters?.find((c) => c.id === chapterId);
}
export async function navigateToChapter(chapterId, libraryObject) {
    const response = await getLibraryObjectAccessInfos({
        isAChapter: true,
        objectId: chapterId
    });
    if (!response.data || response.error) {
        const chapter = getChapter(chapterId, libraryObject).name;
        toast.error(`Falha ao acessar o capítulo "${chapter}"`);
        return;
    }
    if (!response.data.canBeAccessed) {
        const chapter = getChapter(chapterId, libraryObject).name;
        toast.error(`Desculpe, você não tem permissão para acessar o capítulo "${chapter}"`);
        return;
    }
    const link = document.createElement('a');
    link.target = '_blank';
    link.href = response.data.contentUrl;
    link.click();
}
