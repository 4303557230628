export function includesCIAI(source, searchTerm) {
    const normalizedSource = source
        .normalize('NFD')
        .replaceAll(/\p{Diacritic}/gu, '');
    const normalizedSearchTerm = searchTerm
        .normalize('NFD')
        .replaceAll(/\p{Diacritic}/gu, '');
    return normalizedSource
        .toLowerCase()
        .includes(normalizedSearchTerm.toLowerCase());
}
