import styled from 'styled-components';
import { BlackTitle, GreyTitle } from '../../styles';
export const Container = styled.div `
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
`;
export const FirstColumn = styled.div `
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;
export const TitleContainer = styled.div `
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 4px 1fr;
`;
export const VerticalLine = styled.div `
  height: 65px;
  border-right: 1px solid #28293433;
  align-self: flex-end;
  margin-inline: 22px 26px;
`;
export const StyledGreyTitle = styled(GreyTitle) `
  grid-row: 1/2;
  grid-column: 1;

  line-height: 80%;
  font-size: 42px;
  font-weight: 500;
`;
export const StyledBlackTitle = styled(BlackTitle) `
  grid-row: 2/3;
  grid-column: 1;

  line-height: 80%;
  font-size: 42px;
`;
export const Text = styled.span `
  color: ${(props) => props.theme.colors.gray500};
  line-height: 16px;
`;
