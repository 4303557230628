import styled from 'styled-components';
import { ChapterSelector } from '../ChapterSelector';
export const Container = styled.div `
  padding: 38px 0px 50px 0px;
`;
export const MaterialList = styled.div.attrs((props) => ({
    className: (props.className ?? '') +
        (props.$isAnyObjectSelected ? ' with-selection' : '')
})) `
  padding-top: 17px;

  display: grid;
  gap: 26px 20px;
  grid-auto-flow: dense;
  grid-template-columns: repeat(auto-fit, 133px);

  .object {
    transition: translate 150ms;
  }

  &.with-selection .chapters {
    margin-top: calc((26px - 11px) * -1);
  }

  &.with-selection .object.selected {
    translate: 0 -14.59px;
  }

  &.with-selection .object:not(.selected) {
    filter: blur(3px);
  }
`;
export const Chapters = styled(ChapterSelector).attrs((props) => ({
    className: (props.className ?? '') + ' chapters'
})) `
  grid-column: 1 / -1;

  min-height: 247.172px;
`;
