import { createContext, useContext } from 'react';
const instance = createContext(null);
export function useDigitalObjectsPageContext() {
    const context = useContext(instance);
    if (!context) {
        throw new Error('useDigitalObjectsPageContext must be called in a component bounded by digital objects page context');
    }
    return context;
}
export default instance;
