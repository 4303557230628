import styled from 'styled-components';
import { CampusIcon } from '@campus/components';
export const Container = styled.div `
  min-height: 32px;
  height: 32px;

  min-width: min(90vw, 442px);
  width: fit-content;

  padding: 6px 7px;

  display: flex;
  column-gap: 6px;
  align-items: center;

  background: white;

  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.colors.gray200};

  &:has(input:focus-visible) {
    outline: 1px auto;
  }
`;
export const Icon = styled(CampusIcon).attrs({
    name: 'searchInput'
}) `
  display: block;

  path {
    stroke: ${({ theme }) => theme.colors.gray300};
  }
`;
export const Input = styled.input `
  flex: 1;

  display: block;

  padding: 0;

  border: 0;
  outline: 0;

  font-size: 12px;
  font-weight: 500;
  line-height: 20px;

  color: ${({ theme }) => theme.colors.blackAlpha500};

  &::placeholder {
    font: inherit;
  }
`;
