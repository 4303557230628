import styled from 'styled-components';
export const Container = styled.div `
  padding: 38px 30px 90px 30px;
`;
export const TitleCommons = styled.span `
  font-weight: 700;
  font-size: 30px;
  line-height: 100%;
`;
export const GreyTitle = styled(TitleCommons) `
  color: ${(props) => props.theme.colors.gray500};
`;
export const BlackTitle = styled(TitleCommons) `
  color: ${(props) => props.theme.colors.gray800};
`;
export const DisabledItem = styled.div `
  width: ${(props) => props.$width}px;
  height: ${(props) => props.$height}px;

  position: relative;

  opacity: 0.5;

  border-radius: 4px;

  background: #dbdad1;

  box-shadow: 0px 24px 54px -20px rgba(0, 0, 0, 0.3) inset;

  &::before {
    content: '';

    display: block;

    opacity: 0.1;

    background: #fff;

    position: absolute;
    inset: 0;

    border-radius: inherit;
    border: 1px solid #282934;
  }
`;
