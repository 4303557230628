import React from 'react';
import { useDigitalObjectsPageContext } from '../../context';
import { Container, Icon, Input } from './styles';
export const GenericFilter = () => {
    const { events } = useDigitalObjectsPageContext();
    return (React.createElement(Container, null,
        React.createElement(Icon, null),
        React.createElement(Input, { placeholder: "Busque por livros, cap\u00EDtulos, conte\u00FAdos e objetos digitais", onChange: (event) => {
                events.genericFilterChanged.emit(event.target.value);
            } })));
};
