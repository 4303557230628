import styled from 'styled-components';
import { numberOfColumns } from './constants';
export const Wrapper = styled.div `
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1px 1fr;
`;
export const Indicator = styled.div `
  display: contents;

  & > :first-child {
    z-index: 1;

    grid-row: 1 / 3;
    grid-column: 1;

    width: fit-content;

    translate: -50%;

    margin-left: ${(props) => props.$marginLeft}px;
  }

  svg {
    display: block;
    color: rgba(47, 128, 237, 0.2);
  }
`;
export const Content = styled.div `
  grid-row: 2 / -1;
  grid-column: 1;

  position: relative;

  padding: 17px 23px 14px 22px;

  background: white;

  border-radius: 6px;
  border: 1px solid rgba(47, 128, 237, 0.2);

  box-shadow: 0px 9px 40px -20px rgba(0, 0, 0, 0.15) inset;

  &::before {
    content: '';

    display: block;

    border-radius: inherit;

    position: absolute;
    inset: 0;

    opacity: 0.5;
    box-shadow: 0px 24px 54px -20px rgba(0, 0, 0, 0.3) inset;
  }

  & > h4 {
    margin-bottom: 5px;

    font-size: 18px;
    font-weight: 700;
    line-height: 18px;

    color: ${({ theme }) => theme.colors.CSA.darkBlue};
  }
`;
export const Chapters = styled.ol `
  margin: 0;
  padding: 0;

  list-style-type: none;

  display: grid;
  column-gap: 19px;
  grid-template-columns: repeat(${numberOfColumns}, 1fr);
`;
