import React, { useEffect, useRef, useState } from 'react';
import { Skeleton } from '@campus/components';
import { calculateEmptyItemCount, calculateItemsPerLine } from '../../../../utils/elementCount';
import { useDigitalObjectsPageContext } from '../../context';
import { includesCIAI } from '../../utils/strings';
import { BlackTitle, DisabledItem, GreyTitle } from '../../styles';
import DigitalObjectItem from './components/DigitalObjectItem';
import { Container, MaterialList, Chapters } from './styles';
const itemWidth = 133;
const itemHeight = 178;
const columnGap = 20;
const TeachingMaterial = ({ items, loading }) => {
    const materialListRef = useRef(null);
    const chapterListRef = useRef(null);
    const [emptyItemCount, setEmptyItemCount] = useState(0);
    const [itemsPerLine, setItemsPerLine] = useState(0);
    const [filteredItems, setFilteredItems] = useState(items);
    const [selectedObjectId, setSelectedObjectId] = useState(null);
    const { events } = useDigitalObjectsPageContext();
    useEffect(() => {
        setFilteredItems(items);
    }, [items]);
    useEffect(() => {
        setSelectedObjectId((objectId) => {
            return objectId && filteredItems.some((o) => o.id === objectId)
                ? objectId
                : null;
        });
    }, [filteredItems]);
    useEffect(() => {
        return events.genericFilterChanged.subscribe((genericFilter) => {
            if (!genericFilter) {
                setFilteredItems(items);
                return;
            }
            const newFilteredItems = items.filter((item) => (item.name && includesCIAI(item.name, genericFilter)) ||
                item.chapters?.some((c) => c.name && includesCIAI(c.name, genericFilter)));
            setFilteredItems(newFilteredItems);
        });
    }, [events.genericFilterChanged, items]);
    useEffect(() => {
        if (!materialListRef.current)
            return;
        if (loading) {
            setItemsPerLine(calculateItemsPerLine({
                wrapper: materialListRef.current,
                itemWidth,
                columnGap
            }));
            return;
        }
        function recalculateEmptyItemCount() {
            setEmptyItemCount(calculateEmptyItemCount({
                wrapper: materialListRef.current,
                itemCount: filteredItems.length,
                itemWidth,
                columnGap
            }));
        }
        recalculateEmptyItemCount();
        const observer = new ResizeObserver(recalculateEmptyItemCount);
        observer.observe(materialListRef.current, {
            box: 'border-box'
        });
        return () => observer.disconnect();
    }, [filteredItems.length, loading]);
    useEffect(() => {
        function handler(event) {
            if (!(event.target instanceof Element)) {
                setSelectedObjectId(null);
                return;
            }
            const objectElement = event.target.closest('[data-object-id]');
            if (objectElement) {
                setSelectedObjectId(objectElement.dataset['objectId']);
                return;
            }
            if (!chapterListRef.current?.contains(event.target)) {
                setSelectedObjectId(null);
            }
        }
        document.addEventListener('click', handler);
        return () => document.removeEventListener('click', handler);
    }, []);
    useEffect(() => {
        function handler(event) {
            if (selectedObjectId && event.key === 'Escape') {
                setSelectedObjectId(null);
            }
        }
        document.addEventListener('keyup', handler);
        return () => document.removeEventListener('keyup', handler);
    }, [selectedObjectId]);
    return (React.createElement(Container, null,
        React.createElement("div", null,
            React.createElement(GreyTitle, null, "Material "),
            React.createElement(BlackTitle, null, "Did\u00E1tico")),
        React.createElement(MaterialList, { ref: materialListRef, "$isAnyObjectSelected": !!selectedObjectId }, loading ? (Array.from({
            length: itemsPerLine
        }).map((_, i) => {
            return (React.createElement(Skeleton, { key: i, width: itemWidth + 'px', height: itemHeight + 'px' }));
        })) : (React.createElement(React.Fragment, null,
            filteredItems.map((i) => {
                return (React.createElement(React.Fragment, null,
                    React.createElement(DigitalObjectItem, { key: i.id, item: i, "data-object-id": i.id, className: 'object' + (i.id === selectedObjectId ? ' selected' : '') }),
                    selectedObjectId === i.id ? (React.createElement(Chapters, { ref: chapterListRef, libraryObject: i, getTriggerElement: () => {
                            return materialListRef.current.querySelector(`[data-object-id='${i.id}']`);
                        } })) : null));
            }),
            Array.from({
                length: emptyItemCount
            }).map((_, i) => {
                return (React.createElement(DisabledItem, { key: i, "$width": itemWidth, "$height": itemHeight }));
            }))))));
};
export default TeachingMaterial;
