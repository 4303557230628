import React, { useEffect, useState } from 'react';
import { getLibraryObjects } from '../../services/libraryObject';
// import LibraryObjectVideo from '../../models/LibraryObjectVideo';
import { DigitalObjectsPageContextProvider } from './context';
import Header from './components/Header';
import TeachingMaterial from './components/TeachingMaterial';
// import OtherDigitalObjects from './components/OtherDigitalObjects';
import { Container } from './styles';
const Knowledge = () => {
    const [loading, setLoading] = useState(true);
    const [myLibraryObjects, setMyLibraryObjects] = useState([]);
    // const [videos, setVideos] = useState<LibraryObjectVideo[]>([]);
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const result = await getLibraryObjects();
            setMyLibraryObjects(result?.data?.teachingMaterials ?? []);
            // setVideos(result?.data?.otherDigitalObjects ?? []);
            setLoading(false);
        };
        fetchData();
    }, []);
    return (React.createElement(DigitalObjectsPageContextProvider, null,
        React.createElement(Container, null,
            React.createElement(Header, null),
            React.createElement(TeachingMaterial, { items: myLibraryObjects, loading: loading }))));
};
export default Knowledge;
