import { Discipline } from '@campus/commons';
import Grade from './Grade';
import LibraryObjectChapter from './LibraryObjectChapter';
class LibraryObject {
    id;
    name;
    contentType;
    contentUrl;
    imageUrl;
    publishDate;
    discipline;
    grade;
    chapters;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    static fromJson(data) {
        const libraryObject = new LibraryObject();
        libraryObject.id = data?.Id;
        libraryObject.name = data?.Name;
        libraryObject.contentType = data?.ContentType;
        libraryObject.contentUrl = data?.ContentUrl;
        libraryObject.imageUrl = data?.ImageUrl;
        libraryObject.publishDate = data?.PublishDate;
        if (data?.Discipline) {
            libraryObject.discipline = Discipline.fromJson(data?.Discipline);
        }
        if (data?.Grade) {
            libraryObject.grade = Grade.fromJson(data?.Grade);
        }
        libraryObject.chapters =
            data.Chapters?.map((s) => LibraryObjectChapter.fromJson(s)) ??
                [];
        return libraryObject;
    }
}
export default LibraryObject;
