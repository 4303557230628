class LibraryObjectChapter {
    id;
    name;
    contentType;
    contentUrl;
    imageUrl;
    static fromJson(data) {
        const libraryObjectChapter = new LibraryObjectChapter();
        libraryObjectChapter.id = data?.Id;
        libraryObjectChapter.name = data?.Name;
        libraryObjectChapter.contentType = data?.ContentType;
        libraryObjectChapter.contentUrl = data?.ContentUrl;
        libraryObjectChapter.imageUrl = data?.ImageUrl;
        return libraryObjectChapter;
    }
}
export default LibraryObjectChapter;
