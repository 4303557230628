import React from 'react';
import { GenericFilter } from '../GenericFilter';
import { Container, FirstColumn, StyledBlackTitle, StyledGreyTitle, Text, TitleContainer, VerticalLine } from './styles';
const Header = () => {
    return (React.createElement(Container, null,
        React.createElement(FirstColumn, null,
            React.createElement(TitleContainer, null,
                React.createElement(StyledGreyTitle, null, "Campus"),
                React.createElement(StyledBlackTitle, null, "Conhecimento")),
            React.createElement(VerticalLine, null),
            React.createElement(Text, null,
                "Acesse abaixo os materiais",
                React.createElement("br", null),
                "digitais dispon\u00EDveis para voc\u00EA.")),
        React.createElement(GenericFilter, null)));
};
export default Header;
