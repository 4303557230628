import React, { useMemo } from 'react';
import { Event } from '@campus/commons';
import Context from './context';
export const Provider = ({ children }) => {
    const events = useMemo(() => {
        return {
            genericFilterChanged: new Event()
        };
    }, []);
    return React.createElement(Context.Provider, { value: { events } }, children);
};
