import React from 'react';
import LibraryObjectContentType from '../../../../../../models/LibraryObjectContentType';
import { attributes, classNames } from '../../constants';
import { Wrapper, Button, Name, Numberr, IconWrapper, EmbeddedIcon, VideoIcon, TemplateIcon } from './styles';
export const Chapter = ({ chapter, chapterIndex, columnIndex, isLoading, numberWidth, nonClickable, lastOneInColumn }) => {
    return (React.createElement(Wrapper, { ...{
            [attributes.chapterId]: chapter.id,
            [attributes.columnIndex]: columnIndex
        } },
        React.createElement(Button, { disabled: nonClickable, tabIndex: chapterIndex + 1, "$loading": isLoading, "$borderless": lastOneInColumn, "$firstGridColumnWidth": numberWidth },
            React.createElement(Numberr, { className: classNames.chapterNumber },
                chapterIndex + 1,
                "."),
            React.createElement(IconWrapper, null, chapter.contentType === LibraryObjectContentType.EMBEDDED ? (React.createElement(EmbeddedIcon, null)) : chapter.contentType === LibraryObjectContentType.VIDEO ? (React.createElement(VideoIcon, null)) : (React.createElement(TemplateIcon, null))),
            React.createElement(Name, null, chapter.name))));
};
